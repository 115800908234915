.navigation-bar-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  min-height: 0px;
  width: 100%;
  margin-bottom: 20px;
}

.minimized-navigation-bar-header {
  display: initial;
  min-height: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.navigation-button {
  padding: 12px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 19px;
  color: #777;
  text-decoration: none;
}

.navigation-button:hover {
  color: #333;
}

.dropdown {
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease-out; /* For Safari 3.1 to 6.0 */
  transition: max-height 0.4s ease-out;
  display: flex;
  flex-direction: column;
}

.hide-dropdown {
  min-height: 0px;
  max-height: 0px;
}

.show-dropdown {
  min-height: 0px;
  max-height: 93px;
}

.dropdown-button {
  margin-right: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 9px 10px;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-button:hover {
  background-color: #ddd;
}

@media only screen and (min-width: 769px) {
  .navigation-bar-root {
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.9);
    justify-content: center;
    min-height: 94px;
    -webkit-transition: undefined;
    transition: undefined;
  }

  .minimized-navigation-bar-header {
    display: none;
  }

  .navigation-button {
    padding: 35px;
  }

  .dropdown {
    flex-direction: row;
  }

  .show-dropdown {
    min-height: 93px;
  }

  .hide-dropdown {
    min-height: 93px;
  }
}
