.about-root-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.resume-link-container {
  cursor: pointer;
  margin: 32px 0px;
  text-align: center;
}

.resume-link {
  font-size: 22px;
  text-transform: uppercase;
  font-family: Josefin Slab;
  text-decoration: none;
  color: #777;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 30px;
}
