body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  text-transform: uppercase;
  font-family: "Josefin Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 38px;
  font-weight: 700;
  margin: 6px;
}

h2 {
  text-transform: uppercase;
  font-family: "Josefin Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
}

h3 {
  text-transform: uppercase;
  font-family: "Josefin Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0px;
  text-align: center;
}

.root-div {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/hiphop-4ada0.appspot.com/o/background.jpg?alt=media&token=aa2d13f2-3a69-417e-b083-c25a8a77df66);
}
.root-flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
}

.main-title {
  display: none;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  font-family: "Josefin Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 30px 0px 10px 0px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.subtitle {
  display: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 3px;
  color: #fff;
  padding-bottom: 40px;
}

@media only screen and (min-width: 769px) {
  .main-title {
    display: initial;
  }
  .subtitle {
    display: initial;
  }
}
